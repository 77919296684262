// polyfill
import 'intersection-observer';

// libraries
import { useEffect, useRef, useState } from 'react';

import useJsonMemo from './useJsonMemo';

interface Options {
  rootMargin?: string;
  threshold?: number | number[];
}

export default function useIsVisible<T extends Element = Element>(
  options?: Options
) {
  const ref = useRef<T | null>(null);
  const [visible, setVisible] = useState(false);

  const opts = useJsonMemo(options);
  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setVisible(entry.isIntersecting);
    }, opts);

    if (ref.current) observer.observe(ref.current);
    return () => observer.disconnect();
  }, [opts]);

  return [ref, visible] as const;
}
